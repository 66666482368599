@import "reset";
@import "../../../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import '../../app/sass/palette.scss';
@import 'variables';
@import 'mixins';
@import "material";

html, body { height: 100%; }

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: $font-base-size;
  color: $font-base-color;
  margin: 0;
  //overflow-y: scroll;
}

input[type=number],
input[type=textarea],
textarea,
input {
  outline: none;
  box-shadow: unset;
  background-color: unset;
  border: unset;
}

input[type=number],
input[type=textarea],
input {
  height: 30px;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].inputStandard,
.inputStandard {
  position: relative;
  width: 100% !important;
  padding: 0.5em 0.6em 0.5em 0.5em;
  box-sizing: border-box;
  outline: 1px solid rgba(black, 0.2);
}

input[type=number].inputHeader {
  position: relative;
  width: 50% !important;
  padding: 0.5em 0.6em 0.5em 0.5em;
  box-sizing: border-box;
  outline: 1px solid rgba(black, 0.2);
}

input[type=number].inputTable {
  position: relative;
  padding: 0.2em 0.6em 0.2em 0.5em;
  box-sizing: border-box;
  outline: 1px solid rgba($color-campi-open, 0.8);
  width: 80%;
  min-width: 80px;
}

input[type=number].inputTable:disabled {
  font-weight: bold;
  outline: none;
}

::-webkit-scrollbar {
  width: 10px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: $base-portal-color;
  //background-color: #496325;
  border-radius: 10px;
}

.inputStandard:focus,
.inputTable:focus {
  outline: 1px solid rgba($color-primary-light, 1);
}

.inputStandard:disabled {
  background: $color-locked;
}

.form-base {
  .inputStandard:disabled,
  .inputStandard:disabled {
    background: #ededed;
    color: black;
  }
}

.loader-app-component {
  position: fixed;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  color: $base-portal-color;
  //color: #496325;
  font-size: xx-large;
  background-color: rgba(white, 0.4);

  z-index: 40000;
}


a:focus {
  border: none;
  outline: none;
}

.inputStandard.input-error {
  border-style: solid;
  border-width: 0 0 0 2px;
  border-color: $base-color-error;
  //outline: 1px solid rgba(black, 0.2);
}

/* DatePicker */
.data-picker-container {
  input {
    background: white;
  }
}

.fa-icon {
  margin-left: 0.5em;
  //color: #5f5f5f;
  color: gray;
  cursor: pointer;
  font-size: 1.2em;
}

.color-base {
  color: $font-base-color;
}

.color-ok {
  color: $base-color-ok;
}

.color-attenzione {
  color: $base-color-attenzione;
}

.color-assenti {
  color: $base-color-error;
}

.color-info {
  color: $base-color-info;
}

.blink {
  animation: blink 2s alternate infinite ease-in-out;
  -webkit-animation: blink 2s alternate infinite ease-in-out;
}

@media print {
  .noPrint {
    display: none;
  }
}

@keyframes blink{
  0%{opacity: 1;}
  50%{opacity: .4;}
  100%{opacity: 1;}
}

@keyframes load{
  0% { border-color: $color-primary #00A1DE $color-primary $color-primary; color: $color-primary; }
  20% { border-color: $color-primary $color-primary #00A1DE $color-primary; color: $color-primary; }
  40% { border-color: $color-primary $color-primary $color-primary #00A1DE; color: #00A1DE; }
  80% { border-color:  #00A1DE $color-primary $color-primary $color-primary; color: $color-primary; }
  100% { border-color: $color-primary #00A1DE $color-primary $color-primary; color: $color-primary; }
}

@keyframes load2{
  0% { border-color: $color-primary #00A1DE #00C1D4 #00DBB2; }
  20% { border-color: #00DBB2 $color-primary #00A1DE #00C1D4 ; }
  40% { border-color: #00C1D4 #00DBB2 $color-primary #00A1DE; }
  80% { border-color: #00A1DE #00C1D4 #00DBB2 $color-primary; }
  100% { border-color: $color-primary #00A1DE #00C1D4 #00DBB2; }
}
