//Principale (800)

$base-portal-color: #002060; //#496325
$base-text-color: #003366; //#496325
$base-tooltip-color: #0070C0;
$base-loader-color: #3498db;

$md-mcgpalette0: (
        50 : #e0e4ec,
        100 : #b3bccf,
        200 : #8090b0,
        300 : #4d6390,
        400 : #264178,
        500 : #002060,
        600 : #001c58,
        700 : #00184e,
        800 : #002060,
        900 : #002060,
        A100 : #6b7cff,
        A200 : #384eff,
        A400 : #0521ff,
        A700 : #001aeb,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-mcgpalette1: (
        50 : #e0e7ed,
        100 : #b3c2d1,
        200 : #8099b3,
        300 : #4d7094,
        400 : #26527d,
        500 : #003366,
        600 : #002e5e,
        700 : #002753,
        800 : #002049,
        900 : #001438,
        A100 : #6e93ff,
        A200 : #3b6cff,
        A400 : #0846ff,
        A700 : #003bee,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

//Button (700)
$md-mcgpalette2: (
        50 : #e0e7ed,
        100 : #b3c2d1,
        200 : #8099b3,
        300 : #4d7094,
        400 : #26527d,
        500 : #003366,
        600 : #002e5e,
        700 : #0070C0,
        800 : #002049,
        900 : #001438,
        A100 : #6e93ff,
        A200 : #3b6cff,
        A400 : #0846ff,
        A700 : #003bee,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);



/*VERDE*/
/*
$base-portal-color: #496325; //#002060; //#496325
$base-text-color: #496325;//#003366; //#496325
$base-tooltip-color: #94a675; //#371fd5; //#0070C0 ?
$base-loader-color: #94a675; //#3498db;

$md-mcgpalette0: (
        50 : #edf0e7,
        100 : #d1d9c4,
        200 : #b3c09d,
        300 : #94a675,
        400 : #7d9358,
        500 : #66803a,
        600 : #5e7834,
        700 : #536d2c,
        800 : #496325,
        900 : #385018,
        A100 : #caff8e,
        A200 : #b2ff5b,
        A400 : #9aff28,
        A700 : #8eff0e,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$md-mcgpalette1: (
        50 : #edf0e7,
        100 : #d1d9c4,
        200 : #b3c09d,
        300 : #94a675,
        400 : #7d9358,
        500 : #66803a,
        600 : #5e7834,
        700 : #536d2c,
        800 : #496325,
        900 : #385018,
        A100 : #caff8e,
        A200 : #b2ff5b,
        A400 : #9aff28,
        A700 : #8eff0e,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$md-mcgpalette2: (
        50 : #eff3e9,
        100 : #d7e0c8,
        200 : #bdcca3,
        300 : #a2b77e,
        400 : #8ea763,
        500 : #7a9847,
        600 : #729040,
        700 : #678537,
        800 : #5d7b2f,
        900 : #4a6a20,
        A100 : #d8ffa9,
        A200 : #c1ff76,
        A400 : #aaff43,
        A700 : #9fff2a,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);
*/